.contacts {
    display: flex;
    height: 4rem;
    width: 15%;
    align-items: center;
    justify-content: flex-start;
    column-gap: 1rem;
    @media screen and (max-width: 700px) {
        width: 80%;
        margin-top: auto;
        margin-bottom: 2rem;
      }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__item {
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2rem;
        border: 1px solid white;
        background-color: rgb(254, 137, 34);
        @media screen and (max-width: 700px) {
            width: 2rem;
            height: 2rem;
          }
        &_isHover {
            width: 4rem;
            height: 4rem;
            border: none;
            background-color: unset;
            @media screen and (max-width: 700px) {
                width: 3rem;
                height: 3rem;
              }
        }
    }
}