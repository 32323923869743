.NewGamePage {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    box-sizing: border-box;
    padding: 12.5%;
    display: flex;
    align-items: center;

    @media screen and (max-width: 700px) {
        padding: 2rem;
        flex-direction: column-reverse;
        justify-content: space-between;
        padding-top: 0;
    }
}

.Plus {
    color: #ff8d00;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 700px) {
        margin-bottom: 0;
    }
}

.Title {
    font-size: 6rem;
    font-weight: 400;
    font-family: Ponter;
    text-transform: uppercase;
    margin-bottom: 1.5rem;

    &:nth-of-type(2) {
        margin-left: auto;
    }

    @media screen and (max-width: 700px) {
        font-size: 3.4rem;
        margin-bottom: 0;
    }
}

.Description {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    width: 100%;
    line-height: 1.9rem;
    font-weight: 400;

    @media screen and (max-width: 700px) {
        line-height: 130%;
        margin-top: auto;
        margin-bottom: auto;
    }
}

.GameWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 4rem;

    @media screen and (max-width: 700px) {
        padding: 1rem;
    }
}

.Game {
    position: relative;
    width: 40%;
    min-width: 40%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(0deg, #2a3040, #2a3040), #13171d;
    margin-right: 6rem;
    border-radius: 7px;

    &:after {
        content: '';
        padding-top: 100%;
        display: block;
    }

    @media screen and (max-width: 700px) {
        width: 90;
        min-width: 90%;
        margin-right: initial;
    }
}

.GameTop {
    width: 100%;
    height: 55%;
    display: flex;

    @media screen and (max-width: 700px) {
        height: 45%;
    }
}

.GameLogo {
    display: block;
    width: 28%;
    height: 28%;
    position: absolute;
    top: -3rem;
    right: -2rem;
    svg {
      width: 100%;
    }
    @media screen and (max-width: 700px) {
      top: 7%;
      right: 7%;
      transform: none;
    }
}

.GameImg {
    max-height: 100%;
    width: 100%;
    height: 100%;
    display: block;
    width: 50%;

    &-svg {
        max-height: 100%;
        width: 100%;
    }
}

.GameBottom {
    width: 100%;
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 700px) {
      height: 55%;
    }
}

.GameName {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.GameDesc {
    font-size: 1rem;
    font-weight: 500;
    color: #9da8c2;
    margin-bottom: 1rem;
    @media screen and (max-width: 700px) {
      font-size: 0.8rem;
    }
}

.ButtonContainer {
    height: 3rem;
    width: 60%;
    @media screen and (max-width: 700px) {
      height: 1.9rem;
    }
}

.TextContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 700px) {
      flex-grow: initial;
      flex-grow: 1;
    }
}