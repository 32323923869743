.BespokePage {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    box-sizing: border-box;
    padding: 3rem 12.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 700px) {
      padding: 2rem;
      padding-top: 1rem;
    }
}

.MainImage {
    width: 100%;
    max-height: 50%;
    height: 50%;
    @media screen and (max-width: 700px) {
      display: none;
    }
}

.TitleContainer {
    width: 70%;
    display: flex;
    flex-direction: column;
    position: relative;
    @media screen and (max-width: 700px) {
      width: 85%;
    }
}

.Title {
    font-size: 6rem;
    font-weight: 400;
    font-family: Ponter;
    text-transform: uppercase;
    &:first-child {
      margin-bottom: 1rem;
    }
    &:nth-of-type(2) {
        margin-left: auto;
    }
    @media screen and (max-width: 700px) {
      font-size: 3.4rem;
    }
}

.Description {
    font-size: 1.1rem;
    width: 100%;
    line-height: 1.5rem;
    @media screen and (max-width: 700px) {
      line-height: 130%;
    }
}

.ButtonContainer {
    height: 3rem;
    width: 75%;
}

.ContentBlock {
    width: 100%;
    height: 48%;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
      flex-direction: column;
      padding-top: 2rem;
    }
}

.TextBlock {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 5px;
    @media screen and (max-width: 700px) {
      width: 100%;
    }
}

.ButtonBlock {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 5px;
    @media screen and (max-width: 700px) {
      width: 100%;
    }
}

.AddImage {
    width: 100%;
    height: 100%;
    margin-top: 1rem;
    @media screen and (max-width: 700px) {
      display: none;
    }
}

.ButtonText {
    font-size: 1.5rem;
    line-height: 1.9rem;
    font-weight: 500;
    @media screen and (max-width: 700px) {
      line-height: 120%;
      margin-top: auto;
      margin-bottom: 1rem;
    }
}

.Plus {
    color: #ff8d00;
    font-size: 2.5rem;
    position: absolute;
    bottom: 1rem;
    left: 0;
    @media screen and (max-width: 700px) {
      bottom: 100%;
    }
}

.SubTitle {
    font-size: 1.3rem;
    line-height: 1.6rem;
    font-weight: 500;
    padding-bottom: 5px;
    @media screen and (max-width: 700px) {
      line-height: 130%;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
}