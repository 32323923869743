.AboutUsPage {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    box-sizing: border-box;
    padding: 10% 12.5%;
    display: flex;
    @media screen and (max-width: 700px) {
      padding: 2rem;
      padding-top: 0;
    }
}

.AboutUsImage {
    height: 100%;
    width: auto;
    margin-left: auto;
    @media screen and (max-width: 700px) {
      display: none;
    }
}

.Content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    margin-right: 3rem;
    @media screen and (max-width: 700px) {
      margin-right: 0;
      justify-content: space-between;
    }
}

.Plus {
    color: #ff8d00;
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.Title {
    font-size: 5.5rem;
    font-weight: 400;
    font-family: Ponter;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    @media screen and (max-width: 700px) {
      font-size: 3.4rem;
    }
}

.Paragraph {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    width: 90%;
    line-height: 1.6rem;
    @media screen and (max-width: 700px) {
      width: 100%;
      line-height: 130%;
      margin-bottom: 0.5rem;
      font-size: 1.1rem;
    }
}

.StatisticBlock {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
}

.StatisticItem {
    width: 30%;
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and (max-width: 700px) {
      width: 100%;
      flex-direction: row;
      display: flex;
      align-items: center;
      margin-top: 1rem;
    }
}

.StatisticTitle {
    font-weight: 900;
    font-size: 3.6rem;
    background: linear-gradient(223.44deg, #fe8922 0%, #ffa800 102.87%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media screen and (max-width: 700px) {
      font-size: 2rem;
      margin-right: 0.7rem;
    }
}

.StatisticText {
    font-size: 1.4rem;
    @media screen and (max-width: 700px) {
      font-size: 0.9rem;
    }
}