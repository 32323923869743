.OurGamesPage {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    box-sizing: border-box;
    padding: 5% 0 5% 12.5%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 700px) {
      padding: 2rem;
      padding-top: 0;
    }
}

.Plus {
    color: #ff8d00;
    font-size: 2.5rem;
    @media screen and (max-width: 700px) {
    }
}

.ContentTop {
    width: 100%;
    display: flex;
    margin-bottom: 1.5rem;
    @media screen and (max-width: 700px) {
      flex-direction: column;
      margin-bottom: unset;
    }
}

.Title {
    font-size: 5.5rem;
    font-weight: 400;
    font-family: Ponter;
    text-transform: uppercase;
    width: 40%;
    @media screen and (max-width: 700px) {
      font-size: 3.4rem;
      margin-bottom: 1.5rem;
      width: 100%;
    }
}

.Description {
    font-size: 1.5rem;
    width: 45%;
    line-height: 1.5rem;
    font-weight: 400;
    @media screen and (max-width: 700px) {
      font-size: 1.1rem;
      margin-bottom: 1.5rem;
      width: 100%;
    }
}

.Games {
  width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    row-gap: 2rem;
    column-gap: 4rem;
    @media screen and (max-width: 700px) {
      width: calc(100% + 2rem);
      max-width: calc(100% + 2rem);
      overflow-x: scroll;
      row-gap: unset;
      column-gap: unset;
      overflow-y: unset;
      flex-wrap: nowrap;
      margin-top: auto;
      -webkit-overflow-scrolling: touch;
    }
}

.Game {
    width: 25vh;
    height: 27vh;
    min-width: 25vh;
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    background: linear-gradient(0deg, #2a3040, #2a3040), #13171d;
    border-radius: 7px;
    @media screen and (max-width: 700px) {
      width: 65vw;
      height: 75vw;
      min-width: 65vw;
      margin-right: 1rem;
      padding: 1rem;
      display: block;
      box-sizing: unset;
    }
}

.GameTop {
    width: 100%;
    height: 40%;
    display: flex;
}

.GameLogo {
    max-height: 100%;
    width: 100%;
    height: 100%;
    display: block;
    width: 50%;
    &-svg {
      max-height: 100%;
      width: 100%;
    }
}

.GameBottom {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    row-gap: 0.5rem;
}

.GameName {
    font-size: 1.2rem;
    font-weight: 500;
    @media screen and (max-width: 700px) {
      margin-bottom: 0;
    }
}

.GameDesc {
    font-size: 0.8rem;
    line-height: 1;
    font-weight: 500;
    color: #9da8c2;
    overflow-y: scroll;
    min-height: 1rem;
    height: 80%;
    @media screen and (max-width: 700px) {
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
    }
}

.ButtonContainer {
    height: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 5px;
    margin-top: auto;
    @media screen and (max-width: 700px) {
      height: 1.9rem;
      &:last-child {
        margin-top: .5rem;
      }
    }
}