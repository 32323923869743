.ModalWrapper {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    display: flex;
    left: 100%;
    transition-delay: 0,5s;
    z-index: 101;
    align-items: center;
    justify-content: center;
}

.ModalWrapperIsOpen {
    left: 0;
    transition-delay: 0;
}

.FormBlock {
    background: #2a3040;
    box-shadow: 0px 6.3231px 79.0388px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    box-sizing: border-box;
    padding: 2rem 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 40rem;
    position: relative;
    transition: all 0.5s ease-out;
    transform: scale(0);
    @media screen and (max-width: 700px) {
      padding: 2rem;
      max-width: 90%;
    }
}

.FormBlockIsOpen {
    transform: scale(1);
}

.Close {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 4rem;
    * {
      fill: #fff;
    }
    @media screen and (max-width: 700px) {
      right: 1rem;
    }
}

.Title {
    font-size: 1.6rem;
    font-family: Ponter;
    font-weight: 400;
}

.AdditionalText {
    font-weight: 400;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

.Label {
    width: 100%;
    box-sizing: border-box;
}

.LabelText {
    display: block;
    margin-bottom: 0.3rem;
    margin-left: 1rem;
    font-size: 0.8rem;
}

.ButtonContainer {
    height: 3rem;
    width: 100%;
    margin-top: 1rem;
}

.FormElement {
    width: 80%;
    @media screen and (max-width: 700px) {
      width: 100%;
    }
}
