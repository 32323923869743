.LetsTry {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    box-sizing: border-box;
    padding: 1.5% 3%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 700px) {
      padding: 2rem;
      padding-top: 0;
    }
}

.Plus {
    color: #ff8d00;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    @media screen and (max-width: 700px) {
      margin-bottom: 0;
    }
}

.ContentTop {
    width: 100%;
    display: flex;
    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
}

.Title {
    font-size: 5.5rem;
    font-weight: 400;
    font-family: Ponter;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    width: 50%;
    @media screen and (max-width: 700px) {
      font-size: 3.4rem;
      width: 100%;
    }
}

.Description {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    width: 35%;
    line-height: 1.9rem;
    font-weight: 400;
    @media screen and (max-width: 700px) {
      font-size: 1.1rem;
      width: 100%;
    }
}

.DemoContainer {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
    left: 0;
    // z-index: 100;
    @media screen and (max-width: 700px) {
      font-size: 1.1rem;
      position: static;
      flex-direction: column;
      align-items: center;
    }
}

.DemoContainerFull {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background: black;
}

.DemoFrame {
    width: 1200px;
    height: 700px;
    min-height: 720px;
    min-width: 1200px;
    outline: none;
    border: none;
    position: relative;
    @media screen and (max-width: 700px) {
      font-size: 1.1rem;
      height: 100%;
      width: 100%;
      min-height: initial;
      min-width: initial;
    }
}

.DemoFrameFull {
  width: 100%;
  height: 100%;
}

.FrameContainer {

  min-height: 720px;
  min-width: 1200px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 700px) {
    height: 100%;
    width: 100%;
    min-height: initial;
    min-width: initial;
    align-items: center;
  }
}

.FullScreenIconContainer {
  z-index: 5000;
  position: absolute;
  width: 2rem;
  height: 2rem;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
}

.NotFullScreenIconContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
}


.Icon {
  width: 1rem;
  height: 1rem;
}