.LetsTalkPage {
    width: 100%;
    max-height: 100vh;
    box-sizing: border-box;
    padding: 12.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 700px) {
      flex-direction: column-reverse;
      padding: 2rem;
    }
}

.Content {
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-right: 4.5rem;
    justify-content: space-between;
    place-self: stretch;
    font-size: 1.4rem;
    font-weight: normal;
    @media screen and (max-width: 700px) {
      width: 80%;
      margin-right: auto;
      margin-top: 2rem;
    }
}

.ButtonContainer {
    height: 3rem;
    width: 100%;
    margin-top: auto;
    @media screen and (max-width: 700px) {
      margin-top: 3rem;
    }
}

.LetsTalkText {
    font-size: 13rem;
    line-height: 13rem;
    font-weight: 900;
    font-family: Ponter;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: 1px #fe8922;
    letter-spacing: 0.24rem;
    white-space: nowrap;
  
    &:hover {
      color: #fe8922;
    }
  
    @media screen and (max-width: 700px) {
      font-size: 4rem;
      line-height: initial;
    }
}