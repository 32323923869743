.BestPage {
    background: url(../img/main.png);
    background-position-x: 100%;
    // background-repeat: no-repeat;
    /* background-size: contain; */
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 2rem 12.5% 7.5% 12.5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    animation: bgAnimation 6s linear alternate-reverse infinite;
    @media screen and (max-width: 700px) {
      background-size: contain;
      /* background-position-x: -20%; */
      background-size: 180%;
      padding: 2rem;
      align-items: center;
      padding-top: 0;
    }
}

.TopContainer{
    width: 100%;
    display: flex;
    @media screen and (max-width: 700px) {
      margin-top: 2rem;
      margin-bottom: auto;
    }
}

.Logo {
    height: 3rem;
    width: 12.7rem;
    display: block;
    margin-right: auto;
    svg {
      height: 100%;
    }
}

.Burger {
    height: 3rem;
    width: 4.1rem;
    display: block;
    margin-left: auto;
    cursor: pointer;
    svg {
      height: 100%;
    }
    @media screen and (max-width: 700px) {
      height: 2rem;
    }
}

.TopTextBlock {
    width: 60%;
    font-size: 8rem;
    font-weight: 400;
    font-family: Ponter;
    text-transform: uppercase;
    margin-top: auto;
    @media screen and (max-width: 700px) {
      font-size: 3.4rem;
      width: 100%;
      margin-top: initial;
    }
}

.BottomTextBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    margin-bottom: 11%;
    margin-bottom: auto;
  
    @media screen and (max-width: 700px) {
      width: 100%;
      position: relative;
      margin-bottom: initial;
    }
}

.BottomBigText {
    width: 70%;
    font-size: 8rem;
    font-weight: 400;
    font-family: Ponter;
    text-transform: uppercase;
    white-space: nowrap;
    @media screen and (max-width: 700px) {
      font-size: 3.4rem;
      width: 75%;
      margin-left: auto;
      width: initial;
    }
}

.BottomSmallText {
    width: 24%;
    font-size: 1.4rem;
    @media screen and (max-width: 700px) {
      position: absolute;
      top: 110%;
      width: 75%;
      margin-left: 12.5%;
      font-size: 1.1rem;
    }
}

.ButtonContainer {
    height: 3rem;
    width: 15%;
    @media screen and (max-width: 700px) {
      width: 80%;
      margin-top: auto;
      margin-bottom: 2rem;
    }
}

@keyframes bgAnimation {
    from {
        background-position-y: -100%;
      }
    
      to {
        background-position-y: 100%;
      }
}