.SideMenuBlock {
    width: 50%;
    height: 100%;
    display: flex;
    background: #ff8d00;
    border-radius: 0px 0px 0px 10px;
    position: fixed;
    left: 100%;
    transition: all 0.5s ease-out;
    z-index: 100;
    box-sizing: border-box;
    padding: 7.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 700px) {
      width: 100%;
      left: 100%;
      padding: 1.2rem;
    }
}

.SideMenuBlockIsOpen {
    left: 50%;
    @media screen and (max-width: 700px) {
        left: 0;
      }
}

.SideMenuWrapper {
    width: 100%;
    display: flex;
    @media screen and (max-width: 700px) {
      justify-content: space-between;
    }
}

.Menu {
    font-family: Ponter;
    font-style: normal;
    font-weight: normal;
    font-size: 2.5rem;
    width: 70%;
    text-transform: uppercase;
    @media screen and (max-width: 700px) {
      font-size: 1.5rem;
      width: 50%;
    }
}

.MenuItem {
    &:not(:last-child) {
        margin-bottom: 2rem;
      }
      cursor: pointer;
}

.AdditionalBlock {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    place-self: normal;
    @media screen and (max-width: 700px) {
      width: 40%;
    }
}

.ButtonContainer {
  height: 3rem;
  width: 80%;
}

.Close {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    margin-left: auto;
    @media screen and (max-width: 700px) {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
}

.LanguagesBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 700px) {
      flex-direction: column;
      width: initial;
      margin-left: auto;
    }
}

.LangItem {
    width: 5rem;
    height: 5rem;
    border-radius: 100px;
    box-sizing: border-box;
    padding: 1.5rem;
    cursor: pointer;
    text-transform: uppercase;
    font-family: Ponter;
    font-size: 2rem;
  
    @media screen and (max-width: 700px) {
      font-size: 1.5rem;
      width: 4rem;
      height: 4rem;
      padding: initial;
      display: flex;
      align-items: center;
      justify-content: center;
    }
}

.LangItemIsActive {
    border: 1px solid #fff;
}

.Socials {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    align-items: flex-start;
    justify-content: flex-start;
}

.SocialItem {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-weight: 500;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
}

.SocialArrow {
    width: 2.5rem;
    height: 2.5rem;
    @media screen and (max-width: 700px) {
      width: 2rem;
    }
}