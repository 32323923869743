.DemoContainer {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    z-index: 100;
    cursor: pointer;
    &::before {
      content: '+';
      position: absolute;
      font-size: 5rem;
      font-weight: bold;
      color: #FFF;
      right: 2rem;
      top: 2rem;
      transform: rotate(45deg);
    }
}

.DemoFrame {
    width: 1200px;
    height: 700px;
    outline: none;
    border: none;
    position: relative;
}