.button {
  color: #fff;
  font-weight: 500;
  border: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  outline: none;

  &_isActive {
    background: #ff8d00 !important;
  }

  &_isVertical {
    text-orientation: upright;
    writing-mode: vertical-rl;
  }

  &_withArrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_isUppercase {
    text-transform: uppercase;
  }

  &_isBold {
    font-weight: bold;
  }

  &_fontSize {
    &_small {
      font-size: 0.85rem;
      line-height: 0.85rem;

      @media screen and (max-height: 720px) {
        font-size: 0.7rem;
        line-height: 0.7rem;
      }
    }

    &_normal {
      font-size: 1rem;
    }

    &_large {
      font-size: 1.2rem;
    }
  }

  &_color {
    &_orange {
      background: #ff8d00;
      border: 1px solid #ff8d00;
    }

    &_dark {
      background: #2a3040;
      border: 1px solid #ff8d00;
    }

    &_transparent {
      background: transparent;
      border: 1px solid white;

      &:hover {
        background: #ff8d00;
      }
    }
  }

  &_size {
    &_container {
      width: 100%;
      height: 100%;
    }
    &_half {
      width: 50%;
      height: 100%;
    }
  }

  &__layout {
    &_size {
      &_container {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }

  &__arrow {
    width: 1.3rem;
    height: 1.3rem;

    @media screen and (max-height: 720px) {
      font-size: 0.7rem;
      line-height: 0.7rem;
    }
  }
}

@media screen and (min-width: 700px) {
  .button {
    cursor: pointer;

    &__arrow {
      width: 2rem;

      @media screen and (max-height: 720px) {
        width: 1rem;
      }
    }

    &_fontSize {
      &_small {
        font-size: 0.9rem;

        @media screen and (max-height: 720px) {
          font-size: 0.7rem;
          line-height: 0.7rem;
        }
      }

      &_normal {
        font-size: 1rem;
        line-height: 1rem;
      }

      &_large {
        font-size: 1.25rem;
      }
    }
  }
}