#notify-root {
  position: fixed;
  width: 70%;
  right: 1rem;
  display: flex;
  justify-content: center;
  z-index: 1003;
  margin: auto;
  flex-direction: column-reverse;
  overflow: hidden;
}

@media screen and (min-width: 700px) {
  #notify-root {
    width: 15%;
  }
}