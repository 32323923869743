.contact-form {
    position: fixed;
    right: 35px;
    bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}