@font-face {
  font-family: 'Ponter';
  src: url('./services/fonts/ponter.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'IBM';
  src: url('./services/fonts/ibm.ttf');
  font-display: swap;
}

@media screen and (min-width: 700px) {
  #root {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@import url('https://fonts.googleapis.com/css?family=Roboto:100,400,700,900');

#root,
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'IBM', sans-serif;
  background: #13171d;
  // overflow: initial !important;
}

html {
  font-size: 16px;

  @media screen and (min-width: 700px) {
    font-size: 14px;
  }
}

ul,
ol,
li,
p,
a,
h1,
h2,
h3,
h4,
h5,
div,
article,
button,
h6 {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  color: inherit;
  user-select: none;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    margin-top: 3px;
    width: 2px;
    height: 2px;
    opacity: 0;
  }

  &::-webkit-scrollbar-track {
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff8d00;
    border-radius: 50px;
  }
}

@media screen and (orientation: landscape) and (max-device-width: 900px) {
  html {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
    width: 320px;
    /*Разрешение экрана iPhone*/
    position: absolute;
    top: 100%;
    left: 0;
  }
}

@mixin mq-font-by-max-width($fontSize, $maxWidth) {
  @media only screen and (max-width: $maxWidth) {
    html {
      @media screen and (min-width: 700px) {
        font-size: $fontSize;
      }
    }
  }
}

@include mq-font-by-max-width(19px, 2600px);
@include mq-font-by-max-width(17.5px, 2300px);
@include mq-font-by-max-width(16px, 1920px);
@include mq-font-by-max-width(14.5px, 1750px);
@include mq-font-by-max-width(13px, 1600px);
@include mq-font-by-max-width(12px, 1440px);
@include mq-font-by-max-width(11px, 1366px);

@mixin mq-font-by-max-width-mobile($fontSize, $minWidth) {
  @media only screen and (max-width: $minWidth) {
    html {
      @media screen and (max-width: 700px) {
        font-size: $fontSize;
      }
    }
  }
}

@include mq-font-by-max-width-mobile(16px, 700px);
@include mq-font-by-max-width-mobile(14px, 400px);
