.input {
  width: 100%;
  min-height: 2.5em;
  text-align: left;
  box-sizing: border-box;
  outline: 0;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background: none;
  color: #fff;
  border: none;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 2rem;

  @media screen and (max-width: 700px) {
    min-height: 3rem;
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}