.AppMain {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #13171d;
    padding-top: 0;
    color: #fff;
    background-size: cover;
    background-position-y: 5rem;
    position: relative;
}

.Section {
    width: 100%;
    height: 100%;
    display: flex;
}

.AppContent {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}