.OurHighlightsPage {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    box-sizing: border-box;
    padding: 12.5%;
    display: flex;
    align-items: center;

    @media screen and (max-width: 700px) {
        flex-direction: column;
        padding: 2rem;
        padding-top: 0;
        padding-bottom: 1rem;
    }
}

.Title {
    font-size: 6rem;
    font-weight: 400;
    font-family: Ponter;
    text-transform: uppercase;
    white-space: nowrap;
    &:first-child {
      margin-bottom: 1rem;
    }
    &:nth-of-type(2) {
        margin-left: auto;
    }
    @media screen and (max-width: 700px) {
      font-size: 3.4rem;
      &:first-child {
        margin-bottom: 0;
      }
    }
}

.TextBlock {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: initial;
    }
}

.TitleContainer {
    width: 70%;
    display: flex;
    flex-direction: column;
    position: relative;
    @media screen and (max-width: 700px) {
      width: 100%;
    }
}

.Plus {
    color: #ff8d00;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    @media screen and (max-width: 700px) {
      margin-bottom: 0;
    }
}

.SubTitle {
    font-size: 1.3rem;
    line-height: 1.6rem;
    font-weight: 500;
    margin-top: 2.5rem;
    @media screen and (max-width: 700px) {
      font-size: 1.1rem;
      line-height: 130%;
      margin-top: 1rem;
    }
}

.ItemsBlock {
    height: 100%;
    width: 45%;
    max-height: 36rem;
    @media screen and (max-width: 700px) {
      width: 100%;
      margin-top: auto;
      margin-bottom: auto;
      height: initial;
      min-height: initial;
    }
}

.Item {
    height: 10rem;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 2rem 3rem;
    box-sizing: border-box;
    background: linear-gradient(0deg, #2a3040, #2a3040), #13171d;
    border-radius: 10px;
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
    @media screen and (max-width: 700px) {
      padding: 0.7rem;
      height: 7.5rem;
      &:not(:last-child) {
        margin-bottom: 1.4rem;
      }
    }
}

.ItemImage {
    width: 5rem;
    height: 7.2rem;
    @media screen and (max-width: 700px) {
      width: 2.5rem;
      margin-left: auto;
    }
}

.ItemText {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: auto;
    @media screen and (max-width: 700px) {
      width: 75%;
    }
}

.ItemTitle {
    font-size: 1.3rem;
    line-height: 1.6rem;
    font-weight: 500;
    @media screen and (max-width: 700px) {
      font-size: 1.1rem;
      line-height: 110%;
    }
}

.ItemDesc {
    font-size: 1rem;
    color: #9da8c2;
    line-height: 1.2rem;
    font-weight: 400;
    @media screen and (max-width: 700px) {
      font-size: 0.9rem;
      line-height: 110%;
    }
}